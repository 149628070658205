import React from 'react'
import Layout from '../components/layout/Layout'
import PageContainer from '../components/layout/PageContainer'
import Hero from '../components/hero/Hero'
import Heading from '../components/typograph/Heading'
import styled from 'styled-components'
import { DARK_GREEN, TEXT, ORANGE } from '../constants/Colors'

import banner from '../images/BannersHero04-Desk.jpg'
import aspasAntes from '../images/aspas-antes.png'
import aspasDepois from '../images/aspas-depois.png'
import GatsbyLink from 'gatsby-link'

const Container = styled.div`
  max-width: 840px;
  padding: 16px;
  margin: 0 auto;
  text-align: justify;
  color: ${TEXT};
  line-height: 1.5;
`

const MatriculaEnviada = () => (
  <Layout title="Homeopatia: Ciência do 3º Milênio - HDScience">
    <Hero image={banner}>
      <Heading
        type="h1"
        style={{
          color: DARK_GREEN,
          textAlign: 'center',
          paddingTop: 60,
          margin: 0,
        }}
      >
        HOMEOPATIA:
      </Heading>
      <Heading
        type="h2"
        style={{
          color: DARK_GREEN,
          textAlign: 'center',
          paddingTop: 0,
          margin: 0,
        }}
      >
        CIÊNCIA DO 3º MILÊNIO
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <Heading
        type="h3"
        style={{ marginBottom: 16, textAlign: 'center' }}
        color={DARK_GREEN}
      >
        A Medicina do Terceiro Milênio
      </Heading>
      <span
        style={{
          textAlign: 'center',
          width: '100%',
          display: 'block',
          paddingBottom: 32,
        }}
      >
        Por{' '}
        <GatsbyLink
          to="/profissionais/"
          style={{
            textDecoration: 'none',
            color: ORANGE,
            fontWeight: 'bold',
          }}
        >
          Profª Dra. Cidéli Coelho
        </GatsbyLink>{' '}
        e{' '}
        <GatsbyLink
          to="/profissionais/"
          style={{
            textDecoration: 'none',
            color: ORANGE,
            fontWeight: 'bold',
          }}
        >
          Dr. Adalberto von Ancken
        </GatsbyLink>
      </span>
      <Container>
        Estamos vivendo a era das incertezas em várias áreas da sociedade e na
        medicina não é diferente. O corpo humano sadio manifesta-se como uma
        complexa unidade em que milhares de funções devem se coordenar com
        harmonia e equilíbrio. Uma enorme diversidade de células congregam-se no
        exercício de seus respectivos encargos. As necessidades individuais são
        previstas: água, oxigênio e nutrientes devem ser abastecidos
        ininterruptamente e ao mesmo tempo em que excretos são eliminados.
        Corrige-se imediatamente qualquer desvio dos padrões habituais. Mesmo
        com tamanha perfeição, hoje em dia a preocupação maior são os patógenos
        que podem acessar nosso organismo e uma menor preocupação com o “ser” em
        si! Porém com a resistência aos antimicrobianos, alguns pesquisadores já
        estão chegando à conclusão que em um breve futuro não existirão
        antibióticos para as “super bactérias”. Além disto, ainda somam-se os
        efeitos colaterais destas drogas que tem agravado ainda mais a saúde de
        homens e animais. É neste cenário que a Ciência homeopática se
        evidencia!
        <br />
        <br />
        Sendo uma terapêutica médica legalizada há décadas pelos Conselhos de
        medicina e veterinária, a Homeopatia considera o individuo como um todo
        e busca a homeostase (o equilíbrio) do corpo. Estando correto o
        medicamento escolhido, o organismo (inclusive do reino vegetal) será
        capaz de reagir a qualquer infecção seja ela da origem que for. Esta
        terapêutica age também no mental podendo trabalhar na minimização de
        vários distúrbios comportamentais em qualquer espécie animal.
        <br />
        <br />
        Como dizia James Tyler Kent, consagrado homeopata: <br />
        <br />
        <span style={{ paddingLeft: 32, display: 'block' }}>
          <img src={aspasAntes} className="up" alt="Aspas estilizadas" />
          Saúde e doença guardam entranhado relacionamento, sendo a última
          apenas uma variante ou estado anômalo da primeira
          <img src={aspasDepois} alt="Aspas estilizadas" />.
        </span>
        <br />
        <br />
        Assim, Rosenbaum (2005, p. 48) afirma que “...há quem duvide dessa
        associação entre o estado psicológico como produtor ou desencadeador de
        doenças. Já se ouviram cientistas muito respeitados ridicularizando a
        relação. Mas, [...] algumas pesquisas já apontam, por exemplo, para
        evidências bioestatísticas bem estabelecidas de que pessoas que sofrem
        de depressão têm quatro vezes mais probabilidade de desenvolver
        insuficiência cardíaca do que as que não têm o problema”.
        <br />
        <br />
        Observando-se o dinamismo do fenômeno, chega-se à conclusão que o
        indivíduo leva algum tempo para manifestar, de modo ostensivo, sua
        própria doença, denominada como enfermidade homeopática, e definida como
        o conjunto de perturbações orgânicas, sensoriais e psicológicas de uma
        pessoa. Essa trajetória precisa ficar muito bem compreendida desde o
        ponto inicial, do sujeito sadio até o seu fim: sujeito doente. Ele deve
        ser visto em sua unidade, pois é o mesmo indivíduo, porém, nesse último
        estágio, algumas funções se mostram desequilibradas e os eventuais danos
        ou sequelas, sejam mentais ou físicos, podem já estar presentes (Close,
        2000; Vieira, 2013).
        <br />
        <br />
        <strong>
          Assim a Ciência homeopática desponta como uma eficaz Terapêutica para
          o Terceiro Milênio para Homens, animais e plantas.
        </strong>
        <br />
        <br />
        <hr style={{ width: 80, display: 'inline-block' }} />
        <br />
        <strong>REFERÊNCIAS</strong>
        <br />
        <br />
        CLOSE, S.M. The Genius of Homeopathy: Lectures and Essays on Homeopathic
        Philosophy, 2000.
        <br />
        <br />
        Rosenbaum P. Homeopatia Medicina Sob Medida. Publifolha, 2005, 160p.
        <br />
        <br />
        Vieira GR. Homeopatia e Saúde do reducionismo ao sistêmico. Editora da
        Universidade Federal do Acre (Edufac). Rio Branco Acre, 2013, 323p.
      </Container>
    </PageContainer>
  </Layout>
)

export default MatriculaEnviada
